import "../scss/style.scss";
// import slideShow from './modules/slideShow';

// If modals are required, uncomment the below import
// import { generateModal } from './modules/generateModal.js';
// import { youTubePlayerAPI } from './modules/youtubePlayerAPI.js';
import { smoothScroll } from './modules/smoothScroll.js';

// Hello world
console.log(
  "%c Modern English 💻 ",
  "color: white; background: black; padding: 4px 2px;"
);

// this enables the typewriter animations for text on slide 3. it turns every
// <p> into a <p> full of <span>s containing just 1 character and adds
// increasing animation delays to each of them
const initSlide3Animations = () => {
  const lines = [...document.querySelectorAll('.slide-3 p')]

  for (const line of lines) {
    const {innerText} = line;
    let delay = parseFloat(window.getComputedStyle(line).animationDelay.match(/[\d\.]+/)[0]) || 0;
    line.innerHTML = '';
    line.style.opacity = 1;

    [...innerText].forEach((letter) => {
      const span = document.createElement('span');
      span.innerHTML = letter;
      span.style.animationDelay = `${delay}s`;
      delay += .05;
      line.append(span);
    });
  }
};

smoothScroll();

const randomBackground = ()=>{
  /* video assets are copied to dist folder */
  const source = document.createElement('source');
  source.setAttribute('src', "/background-"+Math.floor((Math.random()*3)+1)+".mp4");
  source.setAttribute('type', 'video/mp4');
  document.getElementById('video').appendChild(source);
}

randomBackground();

document.addEventListener('DOMContentLoaded', function () {
  setTimeout(initSlide3Animations, 1000);  // no clue why 1000

  const gallery = document.querySelector('.gallery');
  const galleryImages = [...gallery.querySelectorAll('img:not(.repeated)')].map((img) => img.src);
  let left = galleryImages.length - 2;
  let right = galleryImages.length - 1;

  setTimeout(() => gallery.scrollTo(gallery.clientWidth * 2, 0), 100);

  gallery.addEventListener('scroll', (event) => {
    if (event.target.scrollLeft >= event.target.scrollWidth - event.target.clientWidth * 2) {
      const slide = document.createElement('img');
      right = right >= galleryImages.length - 1 ? 0 : right + 1;
      slide.classList.add('repeated');
      slide.src = galleryImages[right];
      gallery.appendChild(slide);
    } else if (event.target.scrollLeft <= event.target.clientWidth) {
      const slide = document.createElement('img');
      left = left <= 0 ? galleryImages.length - 1 : left - 1;
      slide.classList.add('repeated');
      slide.src = galleryImages[left];

      setTimeout(() => {
        gallery.prepend(slide);
        gallery.scrollTo({left: gallery.clientWidth * 2, top: 0, behavior: 'instant'});
      }, 0);
    }
  });

  document.querySelector('.gallery-arrow-left').addEventListener('click', (event) => {
    gallery.scrollBy(-gallery.clientWidth, 0);
  });

  document.querySelector('.gallery-arrow-right').addEventListener('click', (event) => {
    gallery.scrollBy(gallery.clientWidth, 0);
  });
}, false);
