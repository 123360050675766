export const smoothScroll = () => {

  /*Get all anchor links, you can be more specific or use a classname to be sure it only selects the links you want.*/
  var lnks = document.querySelectorAll('a[href*="#"]');

  /*Loop through each link to add the click event*/
  for (var i = 0; i < lnks.length; i++) {
    lnks[i].onclick = function (e) {
      /*prevent default behavior [clicking through]*/
      e.preventDefault();
      var b = this;

      /*get the name without the hash character and match it to the corresponding anchor*/
      var c = b.getAttribute("href").substring(1);
      var el = document.querySelectorAll('[id="' + c + '"]')[0];
      /*scroll to that position*/
      window.scrollTo({
        top: el.offsetTop,
        behavior: "smooth"
      });
 
    };
  }

}

export default smoothScroll;
